// Colors
// 
//

$base-text:                                   #3e3e29		
$white:                                       #fff				
$mustard:                                     #807b67			
$link:                                        #E87A83			
$header:                                      #68A2A6			
$portfolio-bg:                                #3F4944	
$body-color: 		                          #FAF7F0


// Font weights
//
//

$light:										  300                         
$regular:                                     400                         
$bold:                                        600                         


// Base Font
//
//

// https://www.modularscale.com/?18&px&1.414
$base-font-family:                  		  'Roboto', sans-serif        
$base-font-weight:                            $light                    
$base-font-size:                              18px                        
$base-line-height:                            calc(18px * 1.414)                       
$base-font-color:                             $base-text