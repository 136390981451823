// Header
//
//

header
    background: $header
    padding: 1.414rem 0

    hgroup
        width: 90%
        max-width: 1000px
        margin: auto
        text-align: center

        p.cite
            text-align: right
            color: $white
            margin: 0
            line-height: 2.2rem
