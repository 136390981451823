// footer
    
footer
    clear: both
    width: 100%
    text-align: center

    span
        font-size: .8em
        font-weight: 600
        padding: 18px 0
        display: block